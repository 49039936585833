import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import links from 'RootCommon/links';

import LogoImg from 'imgs/1d1d/pc/nav.logo.png';
import Close from 'imgs/1d1d/m/close.png';
import Line from 'imgs/1d1d/m/line.png';
import Tick from 'imgs/1d1d/m/tick.png';

const Wrapper = styled.header`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  a {
    display: block;
    width: 100%;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 1.04rem;
    padding: 0 0.35rem;
    .mask {
      position: fixed;
      top: 1.04rem;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
    .left {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        img {
          width: 1.57rem;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      img {
        width: 0.33rem;
      }
      .close {
        width: 0.33rem;
      }
    }
  }
  .drop {
    background: #ff7c34;
    visibility: hidden;
    z-index: 0;
    position: absolute;
    width: 100%;
    transition: opacity 0.5s, visibility 0s 0.5s;
    opacity: 0;

    ul {
      li {
        line-height: 0.94rem;
        padding: 0 0.64rem 0 0.32rem;
        font-size: 0.3rem;
        border-bottom: 1px solid #ffaf83;
        overflow: hidden;
        transform: translate3d(0, -80px, 0);
        transition: transform 0.5s, opacity 0.5s;
        opacity: 0;
        a {
          color: #fff;
        }
        .linkWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.94rem;
          span {
            color: #fff;
          }
          .tick {
            width: 0.27rem;
            float: right;
            transform: rotate(0deg);
            transition: transform 0.5s;
          }
        }
        .subs {
          padding-left: 0.2rem;
          line-height: 0.4rem;
          font-size: 0.28rem;
          a {
            display: block;
            height: 0;
            opacity: 0;
            transition: height 0.5s, opacity 0.3s;
          }
        }
      }
      .menu {
        .linkWrapper {
          .tick {
            transform: rotate(180deg);
            transition: transform 0.5s;
          }
        }
        .subs {
          height: max-content;
          transition: height 0.5s;
          a {
            display: block;
            height: 0.6rem;
            opacity: 1;
          }
        }
      }
    }
  }
  .open {
    visibility: visible;
    z-index: 99;
    opacity: 1;
    transition: opacity 0.5s;
    ul {
      li {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
`;
export default function Nav() {
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('');
  const openDrop = () => {
    setIsShowDrop(!isShowDrop);
    const type = isShowDrop ? 'scroll' : 'hidden';
    document.body.style.overflow = type;
  };
  const changeCurrent = (current) => {
    const str = current == currentMenu ? '' : current;
    setCurrentMenu(str);
  };
  return (
    <Wrapper>
      <div className="wrapper">
        {isShowDrop && <div className="mask" onClick={openDrop} />}
        <div className="left">
          <Link to="/">
            <img src={LogoImg} alt="壹点壹滴是中国新幼教云平台" />
          </Link>
        </div>
        <div className="right">
          <img
            src={isShowDrop ? Close : Line}
            alt="logo"
            onClick={openDrop}
            className={isShowDrop ? 'close' : ''}
          />
        </div>
      </div>
      <div className={isShowDrop ? 'drop open' : 'drop'}>
        <ul>
          <li>
            <Link to="/" onClick={openDrop}>
              首页
            </Link>
          </li>
          <li className={currentMenu == 'intro' ? 'menu' : ''}>
            <div className="linkWrapper" onClick={() => changeCurrent('intro')}>
              <span>关于我们</span>
              <img className="tick" src={Tick} />
            </div>
            <div className="subs">
              <Link to="/founders/" onClick={openDrop}>
                企业简介
              </Link>
              <Link to="/culture/" onClick={openDrop}>
                文化&价值观
              </Link>
              {/* <Link to="/hire/" onClick={openDrop}>
                诚聘英才
              </Link> */}
              <Link to="/contact/" onClick={openDrop}>
                联系我们
              </Link>
            </div>
          </li>
          {/**暂时注释 */}
          {/* <li>
            <a href={links.zj} onClick={openDrop}>
              紫荆儿童成长云平台
            </a>
          </li> */}
          <li className={currentMenu == 'service' ? 'menu' : ''}>
            <div className="linkWrapper" onClick={() => changeCurrent('service')}>
              <span>幼教云</span>
              <img className="tick" src={Tick} />
            </div>
            <div className="subs">
              <Link to="/intro/" onClick={openDrop}>
                平台介绍
              </Link>
              <Link to="/space/" onClick={openDrop}>
                幼教云园所服务平台
              </Link>
              <Link to="/service/" onClick={openDrop}>
                幼教云家长服务平台
              </Link>
            </div>
          </li>
          <li>
            <Link to="/partner/" onClick={openDrop}>
              加入我们
            </Link>
          </li>
          <li className={currentMenu == 'news' ? 'menu' : ''}>
            <div className="linkWrapper" onClick={() => changeCurrent('news')}>
              <span>新闻中心</span>
              <img className="tick" src={Tick} />
            </div>
            <div className="subs">
              <Link to="/news/" onClick={openDrop}>
                企业动态
              </Link>
              <Link to="/people/" onClick={openDrop}>
                点滴人物
              </Link>
              <Link to="/videos/" onClick={openDrop}>
                视频专区
              </Link>
            </div>
          </li>
          <li>
            <Link to="/cooperation/" onClick={openDrop}>
              战略合作
            </Link>
          </li>
          <li>
            <a href={links.headmaster}>园长端入口</a>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
}
