import React, { useState } from 'react';
import styled from 'styled-components';
import IconWx from 'imgs/1d1d/m/social.icon.wx.png';
import QrWx from 'imgs/1d1d/qr.wx.jpeg';
import IconTt from 'imgs/1d1d/m/social.icon.tt.png';
import QrTt from 'imgs/1d1d/qr.toutiao.jpeg';
import IconDy from 'imgs/1d1d/m/social.icon.dy.png';
import QrDouyin from 'imgs/1d1d/qr.douyin.jpeg';

const Wrapper = styled.footer`
  background: rgb(234, 234, 234);
  padding-top: 0.54rem;
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .qrCode {
      background: #fff;
      border-radius: 0.2rem;
      width: 4rem;
      margin-bottom: 2.6rem;
      padding-top: 0.3rem;
      img {
        margin: 0 auto;
        display: block;
        width: 3rem;
        height: 3rem;
      }
      .close {
        border: 1px solid #ddd;
        background: #f9f9f9;
        border-radius: 0.1rem;
        font-size: 0.18rem;
        margin: 0.3rem auto;
        width: 1.6rem;
        text-align: center;
        height: 0.6rem;
        line-height: 0.6rem;
      }
    }
  }
  .wrapper {
    .copyright {
      text-align: center;
      p {
        font-size: 0.18rem;
        color: #636363;
        &:last-child {
          margin-top: 0.22rem;
        }
      }
    }
    .rights {
      display: flex;
      padding: 0.24rem 0;
      justify-content: center;
      height: 0.4rem;
      align-items: center;
      .social {
        img {
          width: 0.3rem;
          height: 0.25rem;
          display: block;
        }
        &:not(:last-child) {
          margin-right: 0.24rem;
        }
      }
    }
  }
`;
export default function Footer() {
  const [qrCode, setQrCode] = useState('');
  const setCode = (code) => {
    setQrCode(code);
  };
  return (
    <Wrapper>
      {qrCode && (
        <div className="modal" onClick={() => setCode('')}>
          <div className="qrCode">
            <img src={qrCode} alt="二维码" />
            <p className="close" onClick={() => setCode('')}>
              关闭
            </p>
          </div>
        </div>
      )}
      <div className="wrapper">
        <div className="copyright">
          <p>
            Copyright © All Rights Reserved
            <a
              rel="noopener noreferrer"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030803"
              target="_blank"
            >
              京公网安备 11010802030803号
            </a>
          </p>
          <p>
            <a rel="noopener noreferrer" target="_blank" href="https://beian.miit.gov.cn/">
              京ICP备17062567号
            </a>
          </p>
        </div>
        <div className="rights">
          <p className="social">
            <img src={IconWx} alt="微信图标" onClick={() => setCode(QrWx)} />
          </p>
          <p className="social">
            <img src={IconTt} alt="头条图标" onClick={() => setCode(QrTt)} />
          </p>
          <p className="social">
            <img src={IconDy} alt="抖音图标" onClick={() => setQrCode(QrDouyin)} />
          </p>
        </div>
      </div>
    </Wrapper>
  );
}
